// 提成模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/';

export default {
  /**
   * @description: 提成管理搜索
   * @return {void}
   */
  'POST/commission/manage': (params: any, extra?: Extra) => {
    return axios(`/commission/manage`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建提成管理
   * @return {void}
   */
  'POST/commission/manage/create': (params: any, extra?: Extra) => {
    return axios(`/commission/manage/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成管理详情查询
   * @param {number} manageId 提成管理ID
   * @return {void}
   */
  'POST/commission/manage/detail': (params: {manageId: number}, extra?: Extra) => {
    return axios(`/commission/manage/detail`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成管理详情添加其他明细
   * @return {void}
   */
  'POST/commission/manage/detail/create': (params: any, extra?: Extra) => {
    return axios(`/commission/manage/detail/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成管理详情推送
   * @param {number} id
   * @return {void}
   */
  'PUT/commission/manage/confirm/{id}': (params: {id: number}, extra?: Extra) => {
    return axios(`/commission/manage/confirm/${params.id}`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 提成管理详情推送前校验
   * @param {number} id
   * @return {void}
   */
  'PUT/commission/manage/validate/{id}': (params: {id: number}, extra?: Extra) => {
    return axios(`/commission/manage/validate/${params.id}`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 删除提成管理
   * @param {number} id
   * @return {void}
   */
  'DELETE/commission/manage/{id}': (params: {id: number}) => {
    return axios.delete<void>(`/commission/manage/${params.id}`)
  },
  
  /**
   * @description: 订单提成导出
   * @return {void}
   */
  'POST/commission/export': (params: any, extra?: Extra) => {
    return axios(`/commission/export`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 删除提成管理
   * @param {array} detailIds
   * @return {void}
   */
  'POST/commission/manage/detail/del': (params: {detailIds: number[]}, extra: Extra) => {
    return axios(`/commission/manage/detail/del`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 发放清单-生成凭证
   * @param {array} detailIds
   * @return {void}
   */
  'POST/salaries/distributes/generate_voucher': (params: {detailIds: number[]}, extra: Extra) => {
    return axios(`/salaries/distributes/generate_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 社保缴费明细-上传记录-生成凭证
   * @param {array} detailIds
   * @return {void}
   */
  'POST/social_security_bureau_datas/generate_voucher': (params: {detailIds: number[]}, extra: Extra) => {
    return axios(`/social_security_bureau_datas/generate_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除校验出的错误数据
   * @return {void}
   */
  'POST/commission/manage/validate/del': (params: any, extra: Extra) => {
    return axios(`/commission/manage/validate/del`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成基础
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/base/page': (params: any, extra: Extra) => {
    return axios(`/commission/base/page`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成基础导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/base/export': (params: any, extra: Extra) => {
    return axios(`/commission/base/export`, preParams(params, extra, 'post'))
  },
    
  /**
   * @description: 提成基础明细导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/base/export_detail': (params: any, extra: Extra) => {
    return axios(`/commission/base/export_detail`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 生成提成数据
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/init_base': (params: any, extra: Extra) => {
    return axios(`/commission/init_base`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 删除提成数据
   * @param {string} initFrom
   * @param {string} initTo
   * @return {void}
   */
  'POST/commission/init_base/del': (params: {initFrom: string, initTo: string}, extra: Extra) => {
    return axios(`/commission/init_base/del`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 导入差异金额
   * @return {void}
   */
  'POST/commission/import/diff_amount': (params: any, extra: Extra) => {
    return axios(`/commission/import/diff_amount`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 下载差异金额导入模板
   * @return {void}
   */
  'POST/commission/download/template/diff_amount': (params: any, extra: Extra) => {
    return axios(`/commission/download/template/diff_amount`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改差异金额
   * @param {number} id
   * @param {number} diffAmount
   * @return {void}
   */
  'POST/commission/update/diff_amount': (params: {id: number, diffAmount: number}, extra: Extra) => {
    return axios(`/commission/update/diff_amount`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改是否计算和金额
   * @param {number} id
   * @param {number} data
   * @param {number} data
   * @param {boolean} compute
   * @return {void}
   */
  'POST/commission/update/ratio': (params: {data: number, id: number, compute: boolean}, extra: Extra) => {
    return axios(`/commission/update/ratio`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成率
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/ratio/page': (params: any, extra: Extra) => {
    return axios(`/commission/ratio/page`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成率导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/ratio/export': (params: any, extra: Extra) => {
    return axios(`/commission/ratio/export`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 导入提成项目
   * @param {file} file
   * @return {void}
   */
  'POST/commission/import/ratio': (params: any, extra: Extra) => {
    return axios(`/commission/import/ratio`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 下载导入提成模板
   * @return {void}
   */
  'POST/commission/download/template/ratio': (params: any, extra: Extra) => {
    return axios(`/commission/download/template/ratio`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 新增提成项目
   * @return {void}
   */
  'POST/commission/ratio/add': (params: any, extra: Extra) => {
    return axios(`/commission/ratio/add`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 删除提成项目
   * @param {array} ids
   * @return {void}
   */
  'DELETE/commission/ratio/del': (params: {ids: number[]}, extra: Extra) => {
    return axios.delete<void>(`/commission/ratio/del`, preParams(params, extra, 'delete'))
  },

  /**
   * @description: 提成汇总
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/users': (params: any, extra: Extra) => {
    return axios(`/commission/users`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 获取提成项目
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'GET/commission/types': (params: any, extra: Extra) => {
    return axios.get<void>(`/commission/types`)
  },
    
  /**
   * @description: 提成项目删除
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'DELETE/commission/types/del/{id}': (params: {id: number}, extra: Extra) => {
    return axios.delete<void>(`/commission/types/del/${params.id}`)
  },
    
  /**
   * @description: 提成项目修改或创建
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/types/create_or_update': (params: any, extra: Extra) => {
    return axios(`/commission/types/create_or_update`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 月度汇率查询
   * @param {string} source 原必中
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/month_rate/page': (params: any, extra: Extra) => {
    return axios(`/commission/month_rate/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 月度汇率锁定
   * @param {array} ids
   * @param {boolean} lock true锁定; false解锁
   * @return {void}
   */
  'POST/commission/month_rate/lock': (params: any, extra: Extra) => {
    return axios(`/commission/month_rate/lock`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 回款金额查询
   * @param {string} owner 
   * @param {number} orderNo 订单号
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/order_flow/page': (params: any, extra: Extra) => {
    return axios(`/commission/order_flow/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 回款金额检查
   * @param {string} dateFrom 
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/order_flow/check': (params: any, extra: Extra) => {
    return axios(`/commission/order_flow/check`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 初始化回款金额
   * @param {string} dateFrom 
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/order_flow/init': (params: any, extra: Extra) => {
    return axios(`/commission/order_flow/init`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 回款金额导出
   * @param {string} dateFrom 
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/order_flow/export': (params: any, extra: Extra) => {
    return axios(`/commission/order_flow/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 初始化月度汇率
   * @param {string} dateFrom 
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/month_rate/init': (params: any, extra: Extra) => {
    return axios(`/commission/month_rate/init`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 回款金额删除
   * @param {array} ids
   * @return {void}
   */
  'DELETE/commission/order_flow/delete': (params: {ids: any[]}, extra: Extra) => {
    return axios.delete<void>(`/commission/order_flow/delete`, preParams(params, extra, 'delete'))
  },
  
  /**
   * @description: 回款金额删除（按月删除）
   * @param {string} dateFrom 
   * @param {string} dateTo
   * @return {void}
   */
  'DELETE/commission/order_flow/delete_month': (params: any, extra: Extra) => {
    return axios.delete<void>(`/commission/order_flow/delete_month`, preParams(params, extra, 'delete'))
  },
  
  /**
   * @description: 月度汇率删除
   * @param {array} ids
   * @return {void}
   */
  'DELETE/commission/month_rate/delete': (params: {ids: any[]}, extra: Extra) => {
    return axios.delete<void>(`/commission/month_rate/delete`, preParams(params, extra, 'delete'))
  },

  /**
   * @description: 提成订单查询
   * @param {string} closeTimeFrom 关单时间开始
   * @param {string} closeTimeTo 关单时间结束
   * @return {void}
   */
  'POST/commission/orders': (params: any, extra: Extra) => {
    return axios(`/commission/orders`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成订单查询导出
   * @return {void}
   */
  'POST/commission/orders/export': (params: any, extra: Extra) => {
    return axios(`/commission/orders/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成梯度创建
   * @param {number} min 最小值
   * @param {number} max 最大值
   * @param {number} count 数量
   * @param {string} endDate 结束日期
   * @return {void}
   */
  'POST/commission/gradient/create': (params: {min: number, max: number, count: number, endDate: string}, extra: Extra) => {
    return axios(`/commission/gradient/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成梯度查询
   * @return {void}
   */
  'GET/commission/gradient': (params: any, extra: Extra) => {
    return axios.get(`/commission/gradient`)
  },

  /**
   * @description: 提成梯度删除
   * @param {number} id
   * @return {void}
   */
  'DELETE/commission/gradient/{id}': ({id}: {id: number}) => {
    return axios.delete(`/commission/gradient/${id}`)
  },

  /**
   * @description: 月度提成查询
   * @param {string} owner
   * @param {string} dateFrom
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/month/page': (params: any, extra: Extra) => {
    return axios(`/commission/month/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 月度提成init
   * @param {string} dateFrom
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/month/init': (params: {dateFrom: string, dateTo: string}, extra: Extra) => {
    return axios(`/commission/month/init`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 月度预估提成率修改
   * @param {number} id
   * @param {number} estimateCommissionRate
   * @return {void}
   */
  'POST/commission/month/update': (params: {id: number, estimateCommissionRate: string}, extra: Extra) => {
    return axios(`/commission/month/update`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 预估提成率上传
   * @param {file} file
   * @return {void}
   */
  'POST/commission/month/import/rate': (params: any, extra: Extra) => {
    return axios(`/commission/month/import/rate`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 月度提成删除（按月）
   * @param {string} month
   * @return {void}
   */
  'DELETE/commission/month/delete/{month}': ({month}: {month: string}) => {
    return axios.delete(`/commission/month/delete/${month}`)
  },
  
  /**
   * @description: 月度提成删除（ids）
   * @param {array} ids
   * @return {void}
   */
  'DELETE/commission/month/delete': (params: {ids: number[]}, extra: {}) => {
    return axios.delete(`/commission/month/delete`, preParams(params, extra, 'delete'))
  },
      
  /**
   * @description: 月度提成导出
   * @param {string} dateFrom 起止日期
   * @param {string} dateTo 起止日期
   * @param {string} owner 销售
   * @return {void}
   */
  'POST/commission/month/export': (params: {dateFrom: string, dateTo: string, owner: string}, extra: Extra) => {
    return axios(`/commission/month/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 月度发放查询
   * @param {string} owner 销售
   * @param {string} dateFrom
   * @param {string} dateTo
   * @return {void}
   */
  'POST/commission/distribute/page': (params: {dateFrom: string, dateTo: string, owner: string}, extra: Extra) => {
    return axios(`/commission/distribute/page`, preParams(params, extra, 'post'))
  },
    
  /**
   * @description: 本月发放生成
   * @param {string} month 月份
   * @return {void}
   */
  'POST/commission/distribute/create/{month}': ({month}: {month: string}) => {
    return axios(`/commission/distribute/create/${month}`, preParams({}, {}, 'post'))
  },
    
  /**
   * @description: 月度发放修改USD金额
   * @param {number} id
   * @param {number} usdAmount
   * @return {void}
   */
  'POST/commission/distribute/update': (params: {id: number, usdAmount: number}) => {
    return axios(`/commission/distribute/update`, preParams(params, {}, 'post'))
  },
    
  /**
   * @description: 传输到薪酬
   * @param {string} month 月份
   * @return {void}
   */
  'POST/commission/distribute/push/{month}': ({month}: {month: string}) => {
    return axios(`/commission/distribute/push/${month}`, preParams({}, {}, 'post'))
  },
    
  /**
   * @description: 月度发放删除（按月）
   * @param {string} month
   * @return {void}
   */
  'DELETE/commission/distribute/delete/{month}': ({month}: {month: string}) => {
    return axios.delete(`/commission/distribute/delete/${month}`)
  },
    
  /**
   * @description: 月度发放删除（按月）
   * @param {array} ids
   * @return {void}
   */
  'DELETE/commission/distribute/delete': (params: {ids: number[]}) => {
    return axios.delete(`/commission/distribute/delete`, preParams(params, {}, 'delete'))
  },
    
  /**
   * @description: 月度发放锁定or解锁（按月）
   * @param {string} month
   * @param {string} lock
   * @return {void}
   */
  'PUT/commission/distribute/lock/{month}': ({month, lock}: {month: string, lock: string}) => {
    return axios(`/commission/distribute/lock/${month}?lock=${lock}`, preParams({}, {}, 'put'))
  },

  /**
   * @description: 团长维护查询
   * @param {number} leader 团长userId
   * @param {number} userId 员工userId
   * @return {void}
   */
  'POST/commission/sales_leader/page': (params: {leader: number, userId: number}) => {
    return axios(`/commission/sales_leader/page`, preParams(params, {}, 'post'))
  },
        
  /**
   * @description: 删除团长维护
   * @param {number} id
   * @return {void}
   */
  'DELETE/commission/sales_leader/delete/{id}': ({id}: {id: number}) => {
    return axios.delete(`/commission/sales_leader/delete/${id}`)
  },

  /**
   * @description: 创建团长维护
   * @return {void}
   */
  'POST/commission/sales_leader/create': (params: any, extra: Extra) => {
    return axios(`/commission/sales_leader/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新团长维护
   * @return {void}
   */
  'POST/commission/sales_leader/update': (params: any, extra: Extra) => {
    return axios(`/commission/sales_leader/update`, preParams(params, extra, 'post'))
  },

  /**
   * @description: Share维护查询
   * @return {void}
   */
  'POST/commission/sales_share/page': (params: any, extra: Extra) => {
    return axios(`/commission/sales_share/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: Share维护删除
   * @return {void}
   */
  'POST/commission/sales_share/delete/{id}': (params: any, extra: Extra) => {
    return axios(`/commission/sales_share/delete/${params.id}`, preParams(params, extra, 'post'))
  },

  /**
   * @description: Share维护创建
   * @return {void}
   */
  'POST/commission/sales_share/create': (params: any, extra: Extra) => {
    return axios(`/commission/sales_share/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新Share维护
   * @return {void}
   */
  'POST/commission/sales_share/update': (params: any, extra: Extra) => {
    return axios(`/commission/sales_share/update`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 获取share客户
   * @return {void}
   */
  'GET/customer/drop_list': (params: any, extra: Extra) => {
    return axios.get(`/customer/drop_list`, preParams(params, extra, 'get'))
  },
}
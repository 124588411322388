/* 财务模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {

    // 收款
    /**
     * 接口名：流水发票勾销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2358971
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/flow_invoice_verify': (
      req?: IModels['POST/verification/translation/flow_invoice_verify']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/flow_invoice_verify',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/flow_invoice_verify']>
    },

    /**
     * 接口名：收款科目余额列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2359131
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/receivable/balances': (
      req?: IModels['GET/order/receivable/balances']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/balances',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/receivable/balances']>
    },

    /**
     * 接口名：勾销记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2359135
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/balance_list': (
      req?: IModels['POST/verification/translation/balance_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance_list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/balance_list']>
    },

    /**
     * 接口名：勾销记录删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2359136
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/verification/translation/balance_list/{id}': (
      req?: IModels['DELETE/verification/translation/balance_list/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance_list/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/verification/translation/balance_list/{id}']>
    },

    /**
     * 接口名：获取回款对应客户的所有流水和发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2360497
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/translation/clients/flows_invoices': (
      req?: IModels['PUT/verification/translation/clients/flows_invoices']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/clients/flows_invoices',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/translation/clients/flows_invoices']>
    },

    /**
     * 接口名：计算本位币（发票）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2361761
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/compute_invoice_rate': (
      req?: IModels['POST/order/receivable/compute_invoice_rate']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/compute_invoice_rate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/compute_invoice_rate']>
    },

    /**
     * 接口名：计算本位币（流水）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2361763
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/compute_flow_rate': (
      req?: IModels['POST/order/receivable/compute_flow_rate']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/compute_flow_rate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/compute_flow_rate']>
    },

    /**
     * 接口名：导出应收账款年度统计
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2361950
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/order/receivable/annual_statistics/export': (
      req?: IModels['POST/order/receivable/annual_statistics/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/annual_statistics/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/annual_statistics/export']>
    }, */

    /**
     * 接口名：导出科目余额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2362194
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/receivable/balance/export': (
      req?: IModels['GET/order/receivable/balance/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/balance/export',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/receivable/balance/export']>
    },

    /**
     * 接口名：导入科目余额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2362195
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/balance/import': (
      req?: IModels['POST/order/receivable/balance/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/balance/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/balance/import']>
    },

    /**
     * 接口名：订单查询(条件)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2362304
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/list': (req?: IModels['POST/order/list']['Req'], extra?: commonLib.IExtra) => {
      console.log('req', req)
      return rapperFetch({
        url: '/order/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/list']>
    },

    /**
     * 接口名：计算本位币（订单）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2362796
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/compute_rate': (
      req?: IModels['POST/order/receivable/compute_rate']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/compute_rate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/compute_rate']>
    },

    /**
     * 接口名：勾销记录对应的流水发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2364569
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/translation/balance_list/{id}': (
      req?: IModels['GET/verification/translation/balance_list/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance_list/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/translation/balance_list/{id}']>
    },

    /**
     * 接口名：获取co2订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2366011
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/sync_order': (req?: IModels['POST/order/sync_order']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/sync_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/sync_order']>
    },

    /**
     * 接口名：换算汇率
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2368834
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/covert_rate': (
      req?: IModels['POST/verification/translation/covert_rate']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/covert_rate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/covert_rate']>
    },

    /**
     * 接口名：勾销记录编辑
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2369292
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/balance/update': (
      req?: IModels['POST/verification/translation/balance/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/balance/update']>
    },

    /**
     * 接口名：标记删除发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2370730
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/verification/translation/all_invoice': (
      req?: IModels['DELETE/verification/translation/all_invoice']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/all_invoice',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/verification/translation/all_invoice']>
    },

    /**
     * 接口名：订单导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2375564
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/list/export': (req?: IModels['POST/order/list/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/list/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/list/export']>
    },

    /**
     * 接口名：根据发票获取订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2380294
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/invoices/orders': (
      req?: IModels['POST/verification/translation/invoices/orders']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/invoices/orders',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/invoices/orders']>
    },

    /**
     * 接口名：修改确认关单时间
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2382086
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/update/financial_time': (
      req?: IModels['POST/order/update/financial_time']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/update/financial_time',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/update/financial_time']>
    },

    /**
     * 接口名：依据科目余额更新客户列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2388189
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/receivable/clients/update': (
      req?: IModels['GET/order/receivable/clients/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/update',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/receivable/clients/update']>
    },

    /**
     * 接口名：开票公司列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2388867
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/invoice_companies': (req?: IModels['GET/order/invoice_companies']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/invoice_companies',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/invoice_companies']>
    },

    /**
     * 接口名：修改开票公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2388868
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/update/invoice_company': (
      req?: IModels['POST/order/update/invoice_company']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/update/invoice_company',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/update/invoice_company']>
    },

    /**
     * 接口名：订单状态列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2389942
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/order/status_list': (req?: IModels['GET/order/status_list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/status_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/status_list']>
    }, */

    /**
     * 接口名：科目余额更新
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2390150
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/balances/update': (
      req?: IModels['POST/order/receivable/balances/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/balances/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/balances/update']>
    },

    /**
     * 接口名：应收账款导入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2391902
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/order/receivable/clients/country/batch_update': (
      req?: IModels['POST/order/receivable/clients/country/batch_update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/country/batch_update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/clients/country/batch_update']>
    }, */

    /**
     * 接口名：勾销记录导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2401985
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/balance_list/export': (
      req?: IModels['POST/verification/translation/balance_list/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance_list/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/balance_list/export']>
    },

    /**
     * 接口名：修改国家
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2406401
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/update/country': (req?: IModels['POST/order/update/country']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/update/country',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/update/country']>
    },

    /**
     * 接口名：订单类别下拉框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2421902
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order_categorys/list': (req?: IModels['GET/order_categorys/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_categorys/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order_categorys/list']>
    },

    /**
     * 接口名：批量同步推送勾销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2438915
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/balance_list/push': (
      req?: IModels['POST/verification/translation/balance_list/push']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance_list/push',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/balance_list/push']>
    },

    /**
     * 接口名：获取预付款金额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2443334
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/details/advance_amount': (
      req?: IModels['POST/cost_cash_packs/details/advance_amount']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/details/advance_amount',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/details/advance_amount']>
    },

    /**
     * 接口名：更新金额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2443335
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/details/amount/update': (
      req?: IModels['POST/cost_cash_packs/details/amount/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/details/amount/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/details/amount/update']>
    },

    /**
     * 接口名：订单同步更新记录查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2448540
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sync/order/records': (req?: IModels['POST/sync/order/records']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sync/order/records',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sync/order/records']>
    },

    /**
     * 接口名：回款公司下拉查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2449207
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/dropdown/flow_company': (
      req?: IModels['GET/order/dropdown/flow_company']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/dropdown/flow_company',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/dropdown/flow_company']>
    },

    /**
     * 接口名：新汇率换算
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519137&itf=2452152
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/covert_rate_new': (
      req?: IModels['POST/verification/translation/covert_rate_new']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/covert_rate_new',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/covert_rate_new']>
    },

    // 付款

    /**
 * 接口名：成本应付查询
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2354538
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/cost/conditions': (req?: IModels['POST/cost/conditions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost/conditions']>
    },

    /**
     * 接口名：账款应收查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2355441
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/order/receivable/annual_statistics': (
      req?: IModels['POST/order/receivable/annual_statistics']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/annual_statistics',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/annual_statistics']>
    }, */

    /**
     * 接口名：科目余额_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2357086
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_balance/query': (req?: IModels['POST/cost_balance/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_balance/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_balance/query']>
    },

    /**
     * 接口名：科目余额_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2357089
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/cost_balance': (req?: IModels['PATCH/cost_balance']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_balance',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/cost_balance']>
    },

    /**
     * 接口名：科目余额_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2357087
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_balance': (req?: IModels['POST/cost_balance']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_balance',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_balance']>
    },

    /**
     * 接口名：科目余额_删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2357090
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/cost_balance': (req?: IModels['DELETE/cost_balance']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_balance',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/cost_balance']>
    },

    /**
     * 接口名：导入某月余额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2357416
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/import_balance': (req?: IModels['POST/import_balance']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/import_balance',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/import_balance']>
    },

    /**
     * 接口名：导出某月余额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2357413
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/export_balance': (req?: IModels['POST/export_balance']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/export_balance',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/export_balance']>
    },

    /**
     * 接口名：科目余额_批量修改余额时间
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358403
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/cost_balance/batch': (req?: IModels['PATCH/cost_balance/batch']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_balance/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/cost_balance/batch']>
    },

    /**
     * 接口名：提现_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358433
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/convert_to_cash': (req?: IModels['POST/convert_to_cash']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/convert_to_cash',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/convert_to_cash']>
    },

    /**
     * 接口名：提现_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358434
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/convert_to_cash': (req?: IModels['PATCH/convert_to_cash']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/convert_to_cash',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/convert_to_cash']>
    },

    /**
     * 接口名：译员_同步
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358404
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/translator/sync': (req?: IModels['GET/translator/sync']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator/sync',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/translator/sync']>
    },

    /**
     * 接口名：提现_删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358435
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/convert_to_cash': (req?: IModels['DELETE/convert_to_cash']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/convert_to_cash',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/convert_to_cash']>
    },

    /**
     * 接口名：提现_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358438
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/convert_to_cash/query': (req?: IModels['POST/convert_to_cash/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/convert_to_cash/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/convert_to_cash/query']>
    },

    /**
     * 接口名：RT_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358440
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt/query': (req?: IModels['POST/rt/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt/query']>
    },

    /**
     * 接口名：RT_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358448
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt': (req?: IModels['POST/rt']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt']>
    },

    /**
     * 接口名：RT_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358449
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/rt': (req?: IModels['PATCH/rt']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/rt']>
    },

    /**
     * 接口名：RT_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358451
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/rt': (req?: IModels['DELETE/rt']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/rt']>
    },

    /**
     * 接口名：RT_同步公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2358890
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/rt/sync_company': (req?: IModels['GET/rt/sync_company']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/sync_company',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/rt/sync_company']>
    },

    /**
     * 接口名：提现_批量修改财务日期
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2359697
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/convert_to_cash/batch': (req?: IModels['PATCH/convert_to_cash/batch']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/convert_to_cash/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/convert_to_cash/batch']>
    },

    /**
     * 接口名：RT_批量修改财务日期
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2359700
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/rt/batch': (req?: IModels['PATCH/rt/batch']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/rt/batch']>
    },

    /**
     * 接口名：获取发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2361432
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/invoices': (
      req?: IModels['POST/order/receivable/invoices']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/invoices',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/invoices']>
    },

    /**
     * 接口名：获取订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2361436
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/orders': (
      req?: IModels['POST/order/receivable/orders']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/orders',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/orders']>
    },

    /**
     * 接口名：获取流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2361470
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/flows': (req?: IModels['POST/order/receivable/flows']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receivable/flows',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/flows']>
    },

    /**
     * 接口名：科目余额_批量删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2361910
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/batch_delete': (req?: IModels['DELETE/batch_delete']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/batch_delete',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/batch_delete']>
    },

    /**
     * 接口名：译员_导入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2362716
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/translator/import': (req?: IModels['POST/translator/import']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/translator/import']>
    },

    /**
     * 接口名：译员_导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2362717
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/translator/export': (req?: IModels['POST/translator/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/translator/export']>
    },

    /**
     * 接口名：RT_是否计算
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2363716
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/rt/calculated': (req?: IModels['PATCH/rt/calculated']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/calculated',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/rt/calculated']>
    },

    /**
     * 接口名：RT_导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2363860
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt/export': (req?: IModels['POST/rt/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt/export']>
    },

    /**
     * 接口名：提现_导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2364211
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/convert_to_cash/export': (req?: IModels['POST/convert_to_cash/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/convert_to_cash/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/convert_to_cash/export']>
    },

    /**
     * 接口名：科目余额_根据导入批次删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2365062
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/import_batch_delete': (req?: IModels['DELETE/import_batch_delete']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/import_batch_delete',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/import_batch_delete']>
    },

    /**
     * 接口名：提现_是否计算
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2365774
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/convert_to_cash/calculated': (
      req?: IModels['PATCH/convert_to_cash/calculated']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/convert_to_cash/calculated',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/convert_to_cash/calculated']>
    },

    /**
     * 接口名：RT规则_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2373634
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt_rule/conditions': (req?: IModels['POST/rt_rule/conditions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt_rule/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt_rule/conditions']>
    },

    /**
     * 接口名：RT规则_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2373628
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt_rule': (req?: IModels['POST/rt_rule']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt_rule',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt_rule']>
    },

    /**
     * 接口名：RT规则_删除(取消)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2373630
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/rt_rule': (req?: IModels['DELETE/rt_rule']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt_rule',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/rt_rule']>
    },

    /**
     * 接口名：财务修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2375551
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/convert_to_cash/update_insert': (
      req?: IModels['PATCH/convert_to_cash/update_insert']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/convert_to_cash/update_insert',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/convert_to_cash/update_insert']>
    },

    /**
     * 接口名：同步是否为内部译员(提现)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2381637
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/convert_to_cash/update_is_ccjk_user': (
      req?: IModels['PUT/convert_to_cash/update_is_ccjk_user']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/convert_to_cash/update_is_ccjk_user',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/convert_to_cash/update_is_ccjk_user']>
    },

    /**
     * 接口名：提现_批量修改状态
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2381684
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/convert_to_cash/update_status': (
      req?: IModels['PUT/convert_to_cash/update_status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/convert_to_cash/update_status',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/convert_to_cash/update_status']>
    },

    /**
     * 接口名：成本_导入不计算表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2382866
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt/import_no_calculated': (
      req?: IModels['POST/rt/import_no_calculated']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/rt/import_no_calculated',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt/import_no_calculated']>
    },

    /**
     * 接口名：RT公司列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2390839
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/rt/company/list': (req?: IModels['GET/rt/company/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/company/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/rt/company/list']>
    },

    /**
     * 接口名：提现_手动同步(提现)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2397425
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/convert_to_cash/sync_ctc': (
      req?: IModels['PUT/convert_to_cash/sync_ctc']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/convert_to_cash/sync_ctc',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/convert_to_cash/sync_ctc']>
    },

    /**
     * 接口名：RT_手动同步(RT)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2397426
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/rt/sync_rt': (req?: IModels['PUT/rt/sync_rt']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/sync_rt',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/rt/sync_rt']>
    },

    /**
     * 接口名：发票主体公司列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2399175
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/companies/en_name/{companyId}': (
      req?: IModels['GET/companies/en_name/{companyId}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/companies/en_name/{companyId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/companies/en_name/{companyId}']>
    },

    /**
     * 接口名：发票主体全部公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2399219
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/companies/en_name/list': (req?: IModels['GET/companies/en_name/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/en_name/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/companies/en_name/list']>
    },

    /**
     * 接口名：同步是否为内部译员(RT)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2400632
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/rt/update_is_ccjk_user': (req?: IModels['PUT/rt/update_is_ccjk_user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/update_is_ccjk_user',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/rt/update_is_ccjk_user']>
    },

    /**
     * 接口名：应付账款_导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2401192
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost/download': (req?: IModels['POST/cost/download']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost/download']>
    },

    /**
     * 接口名：创建包
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421515
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/create': (req?: IModels['POST/cost_cash_packs/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/create']>
    },

    /**
     * 接口名：包列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421517
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/cost_cash_packs': (req?: IModels['GET/cost_cash_packs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/cost_cash_packs']>
    },

    /**
     * 接口名：加入包
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421518
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/joining': (
      req?: IModels['POST/cost_cash_packs/joining']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/joining',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/joining']>
    },

    /**
     * 接口名：包页面查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421548
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs': (req?: IModels['POST/cost_cash_packs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs']>
    },

    /**
     * 接口名：删除包
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421573
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/cost_cash_packs/{id}': (req?: IModels['DELETE/cost_cash_packs/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/cost_cash_packs/{id}']>
    },

    /**
     * 接口名：锁定解锁保包
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421576
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/cost_cash_packs/{id}/lock_or_unlock': (
      req?: IModels['PUT/cost_cash_packs/{id}/lock_or_unlock']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/{id}/lock_or_unlock',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/cost_cash_packs/{id}/lock_or_unlock']>
    },

    /**
     * 接口名：包详情（导出）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421579
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/export': (
      req?: IModels['POST/cost_cash_packs/detail/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/export']>
    },

    /**
     * 接口名：包详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421637
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail': (req?: IModels['POST/cost_cash_packs/detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail']>
    },

    /**
     * 接口名：数据检查
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421669
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/check/data': (
      req?: IModels['POST/cost_cash_packs/detail/check/data']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/check/data',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/check/data']>
    },

    /**
     * 接口名：特殊字符检查
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421670
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/check/char': (
      req?: IModels['POST/cost_cash_packs/detail/check/char']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/check/char',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/check/char']>
    },

    /**
     * 接口名：敏感国家检查
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421671
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/check/country': (
      req?: IModels['POST/cost_cash_packs/detail/check/country']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/check/country',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/check/country']>
    },

    /**
     * 接口名：获取国家公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421682
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/cost_cash_packs/detail/queryList': (
      req?: IModels['GET/cost_cash_packs/detail/queryList']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/queryList',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/cost_cash_packs/detail/queryList']>
    },

    /**
     * 接口名：更新包详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2421687
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/details/update': (
      req?: IModels['POST/cost_cash_packs/details/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/details/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/details/update']>
    },

    /**
     * 接口名：RT_导入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2422168
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/rt/import': (req?: IModels['POST/rt/import']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/rt/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/rt/import']>
    },

    /**
     * 接口名：删除支付包详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2422384
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/details/delete': (
      req?: IModels['POST/cost_cash_packs/details/delete']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/details/delete',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/details/delete']>
    },

    /**
     * 接口名：数据验证
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=526627&itf=2432052
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/balance_list/validator': (
      req?: IModels['POST/verification/translation/balance_list/validator']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance_list/validator',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/balance_list/validator']>
    },

    /**
     * 接口名：支付包详情_批量锁定
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/lock': (
      req?: IModels['POST/cost_cash_packs/detail/lock']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/lock',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/lock']>
    },

    /**
     * 接口名：支付包详情_批量解锁
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/unlock': (
      req?: IModels['POST/cost_cash_packs/detail/unlock']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/unlock',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/unlock']>
    },

    /**
     * 接口名：支付包详情_同步MT数据
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/sync_mars': (
      req?: IModels['POST/cost_cash_packs/detail/sync_mars']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/sync_mars',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/sync_mars']>
    },

    /**
     * 接口名：支付包详情_同步MT结果
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/detail/sync_mars_approve': (
      req?: IModels['POST/cost_cash_packs/detail/sync_mars_approve']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/detail/sync_mars_approve',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/detail/sync_mars_approve']>
    },

    /**
     * 接口名：往来请款明细
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/intercourse': (
      req?: IModels['POST/invoice_expense_vouchers/intercourse']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/intercourse',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/intercourse']>
    },

    /**
     * 接口名：财务客户管理列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/client_management/list': (
      req?: IModels['POST/client_management/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/client_management/list']>
    },

    /**
     * 接口名：财务客户管理状态
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/client_management/list_status': (
      req?: IModels['POST/client_management/list_status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/list_status',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/client_management/list_status']>
    },

    /**
     * 接口名：财务客户详情
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/client_management/get_basic_info': (
      req?: IModels['POST/client_management/get_basic_info']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/get_basic_info',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/client_management/get_basic_info']>
    },

    /**
     * 接口名：获取财务客户联系人
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/client_management/get_contacter': (
      req?: IModels['POST/client_management/get_contacter']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/get_contacter',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/client_management/get_contacter']>
    },

    /**
     * 接口名：获取财务客户发票信息
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/client_management/get_financial_info': (
      req?: IModels['POST/client_management/get_financial_info']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/get_financial_info',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/client_management/get_financial_info']>
    },

    /**
     * 接口名：获取财务客户提成信息
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/client_management/get_commission': (
      req?: IModels['POST/client_management/get_commission']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/get_commission',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/client_management/get_commission']>
    },

    /**
     * 接口名：获取增值税发票信息
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/client_management/vat/invoice/{username}': (
      req?: IModels['GET/client_management/vat/invoice/{username}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/client_management/vat/invoice/{username}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/client_management/vat/invoice/{username}']>
    },

    /**
     * 接口名：获取订单成本
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/rt/order/{no}/costs': (
        req?: IModels['GET/rt/order/{no}/costs']['Req'],
        extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/rt/order/{no}/costs',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/rt/order/{no}/costs']>
    },
  }

}